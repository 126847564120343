import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import filterLocale from '../utils/filter-locale'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
	NaturalPoolSection,
	NaturalPoolContainer,
	TextContainer,
	LinkContainer,
	BannerTitle,
	BannerText,
	BannerButton,
	SecondBannerButton,
	OzoneLogo,
	Bold,
} from './styles'

const NaturalPool = ({ locale }) => {
	const { naturalpool, lg, md, sm, xs } = useStaticQuery(graphql`
		query naturalPoolQuery {
			naturalpool: allContentfulBanner(filter: {contentful_id: {eq: "JIdSWsMxsOfxssyYdOu46"}}) {
				edges {
					node {
						hook
						node_locale
						mainActionUrl
						mainActionText
						secondActionUrl
						secondActionText
						image {
							description
							file {
								url
							}
						}
						backgroundImage {
							description
							fluid(
								maxWidth: 1920
								maxHeight: 900
								quality: 80
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						description {
							json
						}
					}
				}
			}
			lg: allContentfulBanner(filter: {contentful_id: {eq: "JIdSWsMxsOfxssyYdOu46"}}) {
				edges {
					node {
						backgroundImage {
							fluid(
								maxWidth: 1279
								maxHeight: 600
								quality: 100
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						node_locale
					}
				}
			}
			md: allContentfulBanner(filter: {contentful_id: {eq: "JIdSWsMxsOfxssyYdOu46"}}) {
				edges {
					node {
						backgroundImage {
							fluid(
								maxWidth: 1023
								maxHeight: 600
								quality: 100
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						node_locale
					}
				}
			}
			sm: allContentfulBanner(filter: {contentful_id: {eq: "JIdSWsMxsOfxssyYdOu46"}}) {
				edges {
					node {
						backgroundImage {
							fluid(
								maxWidth: 767
								maxHeight: 500
								quality: 100
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						node_locale
					}
				}
			}
			xs: allContentfulBanner(filter: {contentful_id: {eq: "JIdSWsMxsOfxssyYdOu46"}}) {
				edges {
					node {
						backgroundImage {
							fluid(
								maxWidth: 524
								maxHeight: 400
								quality: 100
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						node_locale
					}
				}
			}
		}
	`)
	const [
		{
			node: {
				hook,
				mainActionUrl,
				mainActionText,
				secondActionText,
				secondActionUrl,
				image,
				backgroundImage,
				description,
			},
		},
	] = filterLocale(naturalpool.edges, locale)
	const [{ node: lgImage }] = filterLocale(lg.edges, locale)
	const [{ node: medImage }] = filterLocale(md.edges, locale)
	const [{ node: smallImage }] = filterLocale(sm.edges, locale)
	const [{ node: extraSmallImage }] = filterLocale(xs.edges, locale)

	const sources = [
		{
			...backgroundImage.fluid,
			srcSet: `${backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1601px)`,
		},
		{
			...lgImage.backgroundImage.fluid,
			srcSet: `${lgImage.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${lgImage.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...medImage.backgroundImage.fluid,
			srcSet: `${medImage.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${medImage.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...smallImage.backgroundImage.fluid,
			srcSet: `${smallImage.backgroundImage.fluid.src} 768w`,
			srcSetWebp: `${smallImage.backgroundImage.fluid.srcWebp} 768w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
		{
			...extraSmallImage.backgroundImage.fluid,
			srcSet: `${extraSmallImage.backgroundImage.fluid.src} 524w`,
			srcSetWebp: `${extraSmallImage.backgroundImage.fluid.srcWebp} 524w`,
			media: `(max-width: 524px)`,
		},
	]

	const options = {
		renderMark: {
			[MARKS.BOLD]: children => <Bold>{children}</Bold>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<BannerText>{children}</BannerText>
			),
		},
	}

	return (
		<NaturalPoolSection sources={sources}>
			<NaturalPoolContainer sources={sources}>
				<TextContainer>
					<OzoneLogo src={image.file.url} alt={image.description}></OzoneLogo>
					<BannerTitle>{hook}</BannerTitle>
					{documentToReactComponents(description.json, options)}
				</TextContainer>

				<LinkContainer>
					<Link to={'/' + mainActionUrl}>
						<BannerButton>{mainActionText}</BannerButton>
					</Link>
					<Link to={'/' + secondActionUrl}>
						<SecondBannerButton>{secondActionText}</SecondBannerButton>
					</Link>
				</LinkContainer>
			</NaturalPoolContainer>
		</NaturalPoolSection>
	)
}

export default NaturalPool
