import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'

const Reasons = ({ locale }) => {
	return (
		<section tw="py-12">
			<div tw="w-11/12 md:w-8/12 mx-auto flex flex-col items-center">
				<h2 tw="mb-4 text-3xl font-bold font-display">
					Is It Worth Getting A Robotic Pool Cleaner?
				</h2>
				<p tw="mb-8 font-display">
					Absolutely! Swimming pools are meant to be enjoyed, not just become another 
					household chore. Avoid the countless hours of physical labour and let a Dolphin 
					robot take care of your pool maintenance with maximum efficiency. 
					No matter your pool’s size, shape, or surface type, nothing cleans like a Maytronics 
					Dolphin. With powerful filters that can capture even the smallest particles of dirt 
					and debris, our innovative range of robotic cleaners will scrub your entire pool in 
					just a couple of hours.
					By investing in an automatic cleaner, you'll have more time to break out the pool toys 
					for some family fun or relax with a refreshing dip in your crystal clear pool. Here are 
					four reasons why a robot pool cleaner from Maytronics is the best piece of pool equipment 
					you'll ever buy!
				</p>
				<ul tw="flex flex-col">
					<li>
						<h3 tw="font-display text-lg font-bold mb-3">1. Convenience</h3>
						<p tw="font-display mb-8">
							Our automatic cleaners are designed to take all the hard work out of owning a swimming pool. 
							Simply plug-in, press the power button and drop the robot in your pool and soon you’ll
							be enjoying a relaxing dip with your family in pristine water. You
							don’t even need to be home to start the cleaning process. The
							MyDolphin app allows you to easily control your robotic swimming pool
							cleaner from a smartphone or tablet.
						</p>
					</li>
					<li>
						<h3 tw="font-display text-lg font-bold mb-3">2. Performance</h3>
						<p tw="font-display mb-8">
							You can have complete confidence in the powerful cleaning
							performance of your Maytronics robotic pool cleaner. Active
							brushing delivers extra scrubbing and cleaning to remove debris
							from your pool’s surface. Ultra-fine filtration captures and
							removes particles and large debris. The self-learning robot even
							adapts to your pool’s shape and surface type to scrub the floor,
							walls and waterline. In other words, a swimming pool cleaner from
							Maytronics cleans where you need it to clean for crystal clear
							results.
						</p>
					</li>
					<li>
						<h3 tw="font-display text-lg font-bold mb-3">3. Sustainability</h3>
						<p tw="font-display mb-8">
							Living in a more sustainable way doesn’t mean you have to miss out
							on the things you enjoy. That includes swimming in your pool with
							crystal clear water. The Maytronics Dolphin range is the new
							benchmark in environmental sustainability for swimming pools and
							spas for all pool shapes. In fact, it is the world's first climate
							care certified robotic pool cleaner. Maytronics pool cleaners can
							reduce electricity consumption by up to 80% during a cleaning
							session when compared to other traditional pool vacuum cleaners.
							Most pressure pool cleaners and suction cleaners rely on
							high-energy functions associated with the use of water pressure
							and vacuuming when collecting dirt and debris. The Maytronics
							Dolphin is one of the most energy-efficient pool cleaners in
							Australia. Our automatic pool cleaners also save water and improve
							water quality with our innovative pool’s filtration system. You
							can reduce your carbon footprint and save money all while keeping
							your filtration system and pool clean.
						</p>
					</li>
					<li>
						<h3 tw="font-display text-lg font-bold mb-3">4. Support</h3>
						<p tw="font-display mb-8">
							You’re never left on your own when you purchase an auto pool
							cleaner from Maytronics. We have an extensive knowledge base just
							a few clicks away to answer any questions you may have about your
							robotic pool cleaner. We are dedicated to offering you full
							support with your robotic swimming pool cleaner including maintenance and
							troubleshooting to help you keep your pool sparkling clean for years to come.
						</p>
					</li>
				</ul>

				<Link
					to={`${locale === 'en-AU' ? '/' : `/${locale}/`}support/faq/`}
					tw="cursor-pointer w-40 bg-yellow-600 hover:bg-yellow-500 rounded-lg uppercase text-center font-bold py-3 font-display mt-2"
				>
					Learn More
				</Link>
			</div>
		</section>
	)
}

export default Reasons
